'use client'

import { useRef, useEffect } from 'react'
import { motion } from 'framer-motion'
import { useInView } from '@/hooks/useInView'
import Link from 'next/link'
import Image from 'next/image'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import { useRTLAwareStyle } from '@/util/rtl'

import bannerOne from '@/public/images/christmas-banner.jpg'
import bannerTwo from '@/public/images/christmas-banner-2.jpg'
import bannerThree from '@/public/images/christmas-banner-3.jpg'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

type Props = {
  title1: string;
  title2: string;
  lang: string;
  buttonText: string;
  buttonLink: string;
}

export default function Hero({
  title1, 
  title2, 
  lang, 
  buttonText, 
  buttonLink
}: Props) {
  const { ref, isInView } = useInView()
  const rtlAwareUnderlineStyle = useRTLAwareStyle('left-0', 'right-0');

  return (
    <motion.section
      ref={ref}
      initial={{ opacity: 0 }}
      animate={isInView ? { opacity: 1 } : {}}
      transition={{ duration: 0.8, ease: 'easeOut' }}
      className="w-full xl:container mx-auto relative flex flex-col items-center"
    >
      <Swiper
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
    
          clickable: true,
          bulletClass: 'swiper-pagination-bullet',
          bulletActiveClass: 'swiper-pagination-bullet-active',
        }}
        loop={true}
        className="w-full h-[200px] md:h-[400px] lg:h-[550px]"
      >
        {[bannerOne, bannerTwo , bannerThree].map((banner, index) => (
          <SwiperSlide key={index}>
            <div className="relative w-full h-full">
              <Image
                priority
                src={banner}
                alt={`Christmas banner ${index + 1}`}
                quality={85}
                fill
                sizes="100vw"
                className="object-cover"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <Link
        href={buttonLink}
        className="inline-block relative group mt-4"
        prefetch={false}
        >
        <span className="text-gray-600 relative z-10 transition-opacity duration-300 ease-in-out group-hover:opacity-70 font-roboto">
        {buttonText}
        </span>
        <span className={`absolute bottom-0 ${rtlAwareUnderlineStyle} w-full h-[1px] bg-black transition-all duration-700 ease-in-out group-hover:w-0`}></span>
        </Link>

        <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.6, delay: 0.4, ease: 'easeOut' }}
        className={`my-4 md:my-10 text-xl md:text-2xl text-center flex font-roboto ${lang === 'en' && "test"}`}
        >
        {title1} {title2}
        </motion.h1>

        <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.6, delay: 0.6, ease: 'easeOut' }}
        className="mb-6"
        >
        </motion.div>
    </motion.section>
  )
}

